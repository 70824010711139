<template>
  <div id="screenSize" class="mt-down-header flex-center-column-1">
    <div class="right-area">
      <base-breadcrumb :crumbs="items" />
      <div class="rank-list">
        <div style="text-align:center">
          <img class="rank-list-hdimg" src="@/assets/img/ranklist22x.png" alt srcset>
        </div>
        <table class="table c-border">
          <tr class="r-header">
            <th>排名</th>
            <th>策略名称</th>
            <!-- <th>
              年化收益率
              <span>
                <img
                  v-if="sortby[0]==2&&sortby[1]==1"
                  class="sort"
                  src="@/assets/img/a.png"
                  alt
                  srcset
                  @click="sortBy(2)"
                >
                <img
                  v-else-if="sortby[0]==2&&sortby[1]==2"
                  class="sort"
                  src="@/assets/img/d.png"
                  alt
                  srcset
                  @click="sortBy(2)"
                >
                <img
                  v-else
                  class="sort"
                  src="@/assets/img/noaes@2x.png"
                  alt
                  srcset
                  @click="sortBy(2)"
                >
              </span>
            </th> -->
            <th :class="annualize?'actives':''" @click="annualizedsortup">
              年化收益率
              <span>
                <img
                  v-if="annualizedup"
                  class="sort"
                  src="@/assets/img/a.png"
                  alt
                  srcset
                >
                <img
                  v-if="annualizeddown"
                  class="sort"
                  src="@/assets/img/d.png"
                  alt
                  srcset
                >
                <img
                  v-if="!annualizedup&&!annualizeddown"
                  class="sort"
                  src="@/assets/img/noaes@2x.png"
                  alt
                  srcset
                >
              </span>
            </th>
            <!-- <th>
              日收益率
              <span @click="sortBy(1)">
                <img
                  v-if="sortby[0]==1&&sortby[1]==1"
                  class="sort"
                  src="@/assets/img/a.png"
                  alt
                  srcset
                >
                <img
                  v-else-if="sortby[0]==1&&sortby[1]==2"
                  class="sort"
                  src="@/assets/img/d.png"
                  alt
                  srcset
                >
                <img
                  v-else
                  class="sort"
                  src="@/assets/img/noaes@2x.png"
                  alt
                  srcset
                >
              </span>
            </th> -->
            <th :class="daily?'actives':''" @click="dailysortup">
              日收益率
              <span>
                <img
                  v-if="dailyup"
                  class="sort"
                  src="@/assets/img/a.png"
                  alt
                  srcset
                >
                <img
                  v-if="dailydown"
                  class="sort"
                  src="@/assets/img/d.png"
                  alt
                  srcset
                >
                <img
                  v-if="!dailyup&&!dailydown"
                  class="sort"
                  src="@/assets/img/noaes@2x.png"
                  alt
                  srcset
                >
              </span>

            </th>
            <th :class="five?'actives':''" @click="fivesortup">
              5日收益率
              <span>
                <img
                  v-if="fiveup"
                  class="sort"
                  src="@/assets/img/a.png"
                  alt
                  srcset
                >
                <img
                  v-if="fivedown"
                  class="sort"
                  src="@/assets/img/d.png"
                  alt
                  srcset
                >
                <img
                  v-if="!fiveup&&!fivedown"
                  class="sort"
                  src="@/assets/img/noaes@2x.png"
                  alt
                  srcset
                >
              </span>

            </th>
            <th :class="twenty?'actives':''" @click="twentysortup">
              20日收益率
              <span>
                <img
                  v-if="twentyup"
                  class="sort"
                  src="@/assets/img/a.png"
                  alt
                  srcset
                >
                <img
                  v-if="twentydown"
                  class="sort"
                  src="@/assets/img/d.png"
                  alt
                  srcset
                >
                <img
                  v-if="!twentyup&&!twentydown"
                  class="sort"
                  src="@/assets/img/noaes@2x.png"
                  alt
                  srcset
                >
              </span>
            </th>
            <th :class="sixty?'actives':''" @click="sixtysortup">
              60日收益率
              <span>
                <img
                  v-if="sixtyup"
                  class="sort"
                  src="@/assets/img/a.png"
                  alt
                  srcset
                >
                <img
                  v-if="sixtydown"
                  class="sort"
                  src="@/assets/img/d.png"
                  alt
                  srcset
                >
                <img
                  v-if="!sixtyup&&!sixtydown"
                  class="sort"
                  src="@/assets/img/noaes@2x.png"
                  alt
                  srcset
                >
              </span>
            </th>
            <th :class="one_twenty?'actives':''" @click="one_twentysortup">
              120日收益率
              <span>
                <img
                  v-if="one_twentyup"
                  class="sort"
                  src="@/assets/img/a.png"
                  alt
                  srcset
                >
                <img
                  v-if="one_twentydown"
                  class="sort"
                  src="@/assets/img/d.png"
                  alt
                  srcset
                >
                <img
                  v-if="!one_twentyup&&!one_twentydown"
                  class="sort"
                  src="@/assets/img/noaes@2x.png"
                  alt
                  srcset
                >
              </span>
            </th>
            <th :class="maximum?'actives':''" @click="maximumsortup">
              最大回撤
              <span>
                <img
                  v-if="maximumup"
                  class="sort"
                  src="@/assets/img/a.png"
                  alt
                  srcset
                >
                <img
                  v-if="maximumdown"
                  class="sort"
                  src="@/assets/img/d.png"
                  alt
                  srcset
                >
                <img
                  v-if="!maximumup&&!maximumdown"
                  class="sort"
                  src="@/assets/img/noaes@2x.png"
                  alt
                  srcset
                >
              </span>
            </th>

            <th v-if="loginStatus">操作</th>
          </tr>

          <tr v-for="(item,index) in ranklistdata2" :key="index">
            <td>{{ index+1 }}</td>
            <td>
              <router-link
                :to="'/strategy-detail/'+item.class_id+'/'+item.strategy_id"
              >{{ item.strategy_name }}</router-link>
            <!-- {{item.Strategy}} {{item.Strategy === currentStrategy}} -->
            </td>
            <td>{{ item.annualized_return }}</td>
            <td>{{ item.daily_return }}</td>
            <td>{{ item.five_day_return }}</td>
            <td>{{ item.twenty_day_return }}</td>
            <td>{{ item.sixty_day_return }}</td>
            <td>{{ item.one_twenty_day_return }}</td>
            <td>{{ item.maximum_drawdown }}</td>
            <div v-if="loginStatus">
              <td v-if="exptime<=0||isvip ==1" class="text-rigth">
                <b-button
                  size="sm"
                  class="btn"
                  :class="currentStrategy==item.strategy_id?'actived':''"
                  @click="selectStrategy(item.class_id, item.strategy_id, item.strategy_name)"
                >订阅</b-button>
              </td>
              <td v-if="!isvip&&exptime>0">
                <b-button
                  size="sm"
                  style="background:red"
                  @click="becomevip"
                >成为VIP</b-button>
              </td>
            </div>
          </tr>
        </table>
        <div v-if="ranklistdata2.length === 0" class="flex-center" style="min-height:300px">
          <base-spin />
        </div>
      </div>
      <div class="rank-list-2" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import '../../assets/style/common.scss'

export default {
  data() {
    return {
      // 所有up和down状态初始化   为了实现点击排序up和down的图片切换
      // 年化收益率状态
      annualize: false,
      annualizedup: false,
      annualizeddown: false,
      // 日收益率状态
      daily: false,
      dailyup: false,
      dailydown: false,
      // 5日收益状态
      five: false,
      fiveup: false,
      fivedown: false,
      // 20日收益状态
      twenty: false,
      twentyup: false,
      twentydown: false,
      // 60日收益状态
      sixty: false,
      sixtyup: false,
      sixtydown: false,
      // 120日收益状态
      one_twenty: false,
      one_twentyup: false,
      one_twentydown: false,
      // 最大回撤状态
      maximum: false,
      maximumup: false,
      maximumdown: false,
      isvip: localStorage.getItem('isvip'),
      // 面包屑导航
      items: [
        {
          text: '主页',
          route: '/#home'
        },
        {
          text: '排行榜',
          route: ''
        }
      ],
      userinfo: {
        name: '',
        strategyid: ''
      },
      currentStrategy: 0,
      sortby: [0, 2], // [1,2]d
      ranklistdata1: [], // origin
      ranklistdata2: [], // sorted
      allStrategyData: [],
      exptime: ''
    }
  },

  computed: {
    ...mapState({
      ranklist: 'rank/ranklist',
      loginStatus: state => state.login.loginStatus,
      brokerInfoData: state => state.login.brokerInfoData
    })
  },
  beforeMount() {
    // this.initStrategyAll()
    this.intRankList()
  },

  mounted() {
    this.getContainerHeight()
    window.scrollTo(0, 1)
    if (this.loginStatus) {
      this.currentStrategy = sessionStorage.getItem(
        'session_currentStrategy_id'
      )
    }
    if (this.isvip === '1') {
      this.isvip = true
    } else {
      this.isvip = false
    }
  },

  methods: {
    getContainerHeight: function() {
      const screenHeight = document.getElementById('screenSize')
      const pageHeight = window.innerHeight
      screenHeight.style.minHeight = pageHeight + 10 + 'px'
    },
    ...mapActions({
      fetchranklist: 'rank/fetchRanklist'
    }),

    /**
     * init rank list
     */
    intRankList: function() {
      this.http.get('api/office_web/get_list/').then(res => {
        const ranklistdata1 = res.data.data[0]
          .concat(res.data.data[1])
          .concat(res.data.data[2])
          .concat(res.data.data[3])
          .concat(res.data.data[4])
        ranklistdata1.sort((a, b) => {
          return (
            parseFloat(b.annualized_return.replace('%', '')) -
                parseFloat(a.annualized_return.replace('%', ''))
          )
        })
        this.ranklistdata1 = ranklistdata1
        this.ranklistdata2 = ranklistdata1
      })
      const exptime = localStorage.getItem('exptime')
      this.exptime = exptime
    },

    selectStrategy: function(classId, strategyId, strategyName) {
      // const username = sessionStorage.getItem('session_username')
      // console.log('lead-login', this.loginStatus)
      // console.log('this.brokerInfoData', this.brokerInfoData)
      if (this.brokerInfoData.securitiesUsername && this.brokerInfoData.securitiesPassword) {
        const data = {
          new_strategy_id: strategyId,
          new_class_id: classId
        }
        if (confirm('确定使用新的策略吗？')) {
          this.http
            .post(
              'api/office_web/update_personal_strategy/',
              data
            )
            .then(res => {
              if (res.data.data === 'success') {
                this.currentStrategy = strategyId
                setTimeout(
                  this.$router.push('user-account'),
                  2000
                )
                setTimeout(
                  alert(`正在使用"${strategyName}"策略，\n点击【停止状态】按钮开始交易！`),
                  2000
                )
                // console.log('res', res)
              } else {
                confirm('更改失败，请联系管理员！')
              }
            })
        }
      } else {
        if (confirm('需要提供券商账号！')) {
          this.$router.push({ path: '/broker' })
        }
      }
    },
    // 成为VIP
    becomevip() {
      this.$router.push({ path: '/pay' })
    },
    // 年化收益率排序
    annualizedsortup() {
      this.annualize = true
      // 所有初始状态改为false，将别的排序按钮归为初始状态
      this.daily = false
      this.dailyup = false
      this.dailydown = false
      this.five = false
      this.fiveup = false
      this.fivedown = false
      this.twenty = false
      this.twentyup = false
      this.twentydown = false
      this.sixty = false
      this.sixtyup = false
      this.sixtydown = false
      this.one_twenty = false
      this.one_twentyup = false
      this.one_twentydown = false
      this.maximum = false
      this.maximumup = false
      this.maximumdown = false
      if (this.annualizedup) {
        this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
          return (
            parseFloat(a.annualized_return.replace('%', '')) -
                parseFloat(b.annualized_return.replace('%', ''))
          )
        })
        this.annualizeddown = true
        this.annualizedup = false
      } else {
        this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
          return (
            parseFloat(b.annualized_return.replace('%', '')) -
                parseFloat(a.annualized_return.replace('%', ''))
          )
        })
        this.annualizedup = true
        this.annualizeddown = false
      }
    },
    // 日收益率
    dailysortup() {
      this.daily = true
      // 所有初始状态改为false，将别的排序按钮归为初始状态
      this.annualize = false
      this.annualizedup = false
      this.annualizeddown = false
      this.five = false
      this.fiveup = false
      this.fivedown = false
      this.twenty = false
      this.twentyup = false
      this.twentydown = false
      this.sixty = false
      this.sixtyup = false
      this.sixtydown = false
      this.one_twenty = false
      this.one_twentyup = false
      this.one_twentydown = false
      this.maximum = false
      this.maximumup = false
      this.maximumdown = false
      if (this.dailyup) {
        this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
          return (
            parseFloat(a.daily_return.replace('%', '')) -
                parseFloat(b.daily_return.replace('%', ''))
          )
        })
        this.dailydown = true
        this.dailyup = false
      } else {
        this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
          return (
            parseFloat(b.daily_return.replace('%', '')) -
                parseFloat(a.daily_return.replace('%', ''))
          )
        })
        this.dailyup = true
        this.dailydown = false
      }
    },
    // 5日收益率排序
    fivesortup() {
      this.five = true
      // 所有初始状态改为false，将别的排序按钮归为初始状态
      this.annualize = false
      this.annualizedup = false
      this.annualizeddown = false
      this.daily = false
      this.dailyup = false
      this.dailydown = false
      this.twenty = false
      this.twentyup = false
      this.twentydown = false
      this.sixty = false
      this.sixtyup = false
      this.sixtydown = false
      this.one_twenty = false
      this.one_twentyup = false
      this.one_twentydown = false
      this.maximum = false
      this.maximumup = false
      this.maximumdown = false
      if (this.fiveup) {
        this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
          return (
            parseFloat(a.five_day_return.replace('%', '')) -
                parseFloat(b.five_day_return.replace('%', ''))
          )
        })
        this.fivedown = true
        this.fiveup = false
      } else {
        this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
          return (
            parseFloat(b.five_day_return.replace('%', '')) -
                parseFloat(a.five_day_return.replace('%', ''))
          )
        })
        this.fiveup = true
        this.fivedown = false
      }
    },
    // 20日收益率排序
    twentysortup() {
      this.twenty = true
      // 所有初始状态改为false，将别的排序按钮归为初始状态
      this.annualize = false
      this.annualizedup = false
      this.annualizeddown = false
      this.daily = false
      this.dailyup = false
      this.dailydown = false
      this.five = false
      this.fiveup = false
      this.fivedown = false
      this.sixty = false
      this.sixtyup = false
      this.sixtydown = false
      this.one_twenty = false
      this.one_twentyup = false
      this.one_twentydown = false
      this.maximum = false
      this.maximumup = false
      this.maximumdown = false
      if (this.twentyup) {
        this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
          return (
            parseFloat(a.twenty_day_return.replace('%', '')) -
                parseFloat(b.twenty_day_return.replace('%', ''))
          )
        })
        this.twentydown = true
        this.twentyup = false
      } else {
        this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
          return (
            parseFloat(b.twenty_day_return.replace('%', '')) -
                parseFloat(a.twenty_day_return.replace('%', ''))
          )
        })
        this.twentyup = true
        this.twentydown = false
      }
    },
    // 60日收益率排序
    sixtysortup() {
      this.sixty = true
      // 所有初始状态改为false，将别的排序按钮归为初始状态
      this.annualize = false
      this.annualizedup = false
      this.annualizeddown = false
      this.daily = false
      this.dailyup = false
      this.dailydown = false
      this.five = false
      this.fiveup = false
      this.fivedown = false
      this.twenty = false
      this.twentyup = false
      this.twentydown = false
      this.one_twenty = false
      this.one_twentyup = false
      this.one_twentydown = false
      this.maximum = false
      this.maximumup = false
      this.maximumdown = false
      if (this.sixtyup) {
        this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
          return (
            parseFloat(a.sixty_day_return.replace('%', '')) -
                parseFloat(b.sixty_day_return.replace('%', ''))
          )
        })
        this.sixtydown = true
        this.sixtyup = false
      } else {
        this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
          return (
            parseFloat(b.sixty_day_return.replace('%', '')) -
                parseFloat(a.sixty_day_return.replace('%', ''))
          )
        })
        this.sixtyup = true
        this.sixtydown = false
      }
    },
    // 120日收益率排序
    one_twentysortup() {
      this.one_twenty = true
      // 所有初始状态改为false，将别的排序按钮归为初始状态
      this.annualize = false
      this.annualizedup = false
      this.annualizeddown = false
      this.daily = false
      this.dailyup = false
      this.dailydown = false
      this.five = false
      this.fiveup = false
      this.fivedown = false
      this.twenty = false
      this.twentyup = false
      this.twentydown = false
      this.sixty = false
      this.sixtyup = false
      this.sixtydown = false
      this.maximum = false
      this.maximumup = false
      this.maximumdown = false
      if (this.one_twentyup) {
        this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
          return (
            parseFloat(a.one_twenty_day_return.replace('%', '')) -
                parseFloat(b.one_twenty_day_return.replace('%', ''))
          )
        })
        this.one_twentydown = true
        this.one_twentyup = false
      } else {
        this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
          return (
            parseFloat(b.one_twenty_day_return.replace('%', '')) -
                parseFloat(a.one_twenty_day_return.replace('%', ''))
          )
        })
        this.one_twentyup = true
        this.one_twentydown = false
      }
    },
    // 最大回撤
    maximumsortup() {
      this.maximum = true
      // 所有初始状态改为false，将别的排序按钮归为初始状态
      this.annualize = false
      this.annualizedup = false
      this.annualizeddown = false
      this.daily = false
      this.dailyup = false
      this.dailydown = false
      this.five = false
      this.fiveup = false
      this.fivedown = false
      this.twenty = false
      this.twentyup = false
      this.twentydown = false
      this.sixty = false
      this.sixtyup = false
      this.sixtydown = false
      this.one_twenty = false
      this.one_twentyup = false
      this.one_twentydown = false
      if (this.maximumup) {
        this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
          return (
            parseFloat(a.maximum_drawdown.replace('%', '')) -
                parseFloat(b.maximum_drawdown.replace('%', ''))
          )
        })
        this.maximumdown = true
        this.maximumup = false
      } else {
        this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
          return (
            parseFloat(b.maximum_drawdown.replace('%', '')) -
                parseFloat(a.maximum_drawdown.replace('%', ''))
          )
        })
        this.maximumup = true
        this.maximumdown = false
      }
    },
    // 可以变少为现有1/3行
    sortBy: function(filedid) {
      // console.log('beforeSort:', this.ranklistdata2)
      const currentSortFiled = this.sortby[0]
      const sortMode = this.sortby[1]
      if (currentSortFiled === filedid) {
        // 倒序
        if (sortMode === 2) {
          this.sortby = [filedid, 1]
          if (filedid === 7) {
            this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
              // return a.maximum_drawdown - b.maximum_drawdown;
              return (
                parseFloat(a.maximum_drawdown.replace('%', '')) -
                parseFloat(b.maximum_drawdown.replace('%', ''))
              )
            })
          }
          if (filedid === 6) {
            this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
              // return a.maximum_drawdown - b.maximum_drawdown;
              return (
                parseFloat(a.maximum_drawdown.replace('%', '')) -
                parseFloat(b.maximum_drawdown.replace('%', ''))
              )
            })
          }
          if (filedid === 5) {
            this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
              // return a.maximum_drawdown - b.maximum_drawdown;
              return (
                parseFloat(a.maximum_drawdown.replace('%', '')) -
                parseFloat(b.maximum_drawdown.replace('%', ''))
              )
            })
          }
          if (filedid === 4) {
            this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
              // return a.maximum_drawdown - b.maximum_drawdown;
              return (
                parseFloat(a.maximum_drawdown.replace('%', '')) -
                parseFloat(b.maximum_drawdown.replace('%', ''))
              )
            })
          }
          if (filedid === 3) {
            this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
              // return a.maximum_drawdown - b.maximum_drawdown;
              return (
                parseFloat(a.maximum_drawdown.replace('%', '')) -
                parseFloat(b.maximum_drawdown.replace('%', ''))
              )
            })
          }
          if (filedid === 2) {
            this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
              return (
                parseFloat(b.annualized_return.replace('%', '')) -
                parseFloat(a.annualized_return.replace('%', ''))
              )
            })
          }
          if (filedid === 1) {
            this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
              return (
                parseFloat(b.daily_return.replace('%', '')) -
                parseFloat(a.daily_return.replace('%', ''))
              )
            })
          }
        }
        // 正序
        if (sortMode === 1) {
          this.sortby = [filedid, 2]
          if (filedid === 3) {
            this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
              // return b.maximum_drawdown - a.maximum_drawdown;
              return (
                parseFloat(a.maximum_drawdown.replace('%', '')) -
                parseFloat(b.maximum_drawdown.replace('%', ''))
              )
            })
          }
          if (filedid === 2) {
            this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
              return (
                parseFloat(a.annualized_return.replace('%', '')) -
                parseFloat(b.annualized_return.replace('%', ''))
              )
            })
          }
          if (filedid === 1) {
            this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
              return (
                parseFloat(a.daily_return.replace('%', '')) -
                parseFloat(b.daily_return.replace('%', ''))
              )
            })
          }
        }
      } else {
        this.sortby = [filedid, 2]
        if (filedid === 3) {
          this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
            // return b.maximum_drawdown - a.maximum_drawdown;
            return (
              parseFloat(b.maximum_drawdown.replace('%', '')) -
              parseFloat(a.maximum_drawdown.replace('%', ''))
            )
          })
        }
        if (filedid === 2) {
          this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
            return (
              parseFloat(a.annualized_return.replace('%', '')) -
              parseFloat(b.annualized_return.replace('%', ''))
            )
          })
        }
        if (filedid === 1) {
          this.ranklistdata2 = this.ranklistdata1.sort(function(a, b) {
            return (
              parseFloat(a.daily_return.replace('%', '')) -
              parseFloat(b.daily_return.replace('%', ''))
            )
          })
        }
      }
      // console.log(this.ranklistdata2)
    }
  }
}
</script>

<style lang="scss" scoped>
.right-area {
  padding-left: 12px;
  width: 1200px;
  .bg {
    background: rgba(250, 250, 250, 1);
  }
  .rank-list {
    .rank-list-hdimg {
      margin-top: 30px;
      // margin-bottom: 15px;
    }
  }
  // .area {
  //   width: 100%;
  //   // height: 161px;
  //   margin-top: 12px;
  //   // background: rgba(250, 250, 250, 1);
  // }
  table {
    margin-top: 45px;
    .r-header {
      height: 20px;
      font-size: 15px;
    }
    .sort {
      cursor: pointer;
    }
    tr:nth-child(2n + 1) {
      background: rgba(245, 245, 245, 1);
    }
    .btn {
      background: rgba(247, 220, 202, 1);
    }
    .btn:active {
      background: rgba(255, 126, 40, 1);
    }
    .actived {
      background: rgba(255, 126, 40, 1);
      pointer-events: none;
    }
  }
}

.flex-center-column-1 {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
}
// 收益率排行点击颜色
.actives{
  color: red;
}
.table th:nth-child(n+3):nth-child(-n+9){
  cursor: pointer;
}
</style>
